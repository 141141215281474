import React, { Component } from 'react';
import { sentAnalyticsEvent } from '../base/analytics';
import sso from '../base/sso';
import { queryString } from '../lib';

const Titles = () =>
    ([
        <div className='imagine-title' key='imagine-title' data-what='title' data-which='imagine'>{ gettext('Imagine') }</div>,
        <div className='possibilities-title' key='possibilities-title' data-what='title' data-which='possibilities'>{ gettext('the possibilities') }</div>
    ]);

class LoginForm extends Component {
    componentDidMount () {
        $(document).on('click', this.sendGA);
    }

    componentWillUnmount () {
        $(document).off('click', this.sendGA);
    }

    componentDidCatch (error, errorInfo) {
        window.Sentry.captureException(error, { extra: errorInfo }); // eslint-disable-line
    }

    sendGA = event => {
        const action = event.target.getAttribute('ga-action');
        const label = event.target.getAttribute('ga-label');
        action && label && sentAnalyticsEvent({ action, label }, { event });
    };

    login = () => {
        const qs = queryString.parse(window.location.search);
        const next = qs.next || window.encodeURIComponent(Settings.HOME_URL);
        sso.login(next);
    };

    render () {
        return [
            <Titles key='titles'/>,
            <div className='register-link' key='register'>
                <span
                    onClick={this.login}
                    className='btn btn-primary'
                    ga-action='Login_Page'
                    ga-label='Click_Sign_In'
                    data-what='login-page-btn'
                    data-which='login'
                >
                    { gettext('SIGN IN') }
                </span>
            </div>
        ];
    }
}

export default LoginForm;
